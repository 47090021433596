//import API from "../../utils/API";
import axios from "axios";
export const FETCH_CITIES = "FETCH_CITIES";

export const fetchCities = (cities) => {
  return (dispatch) => {
    axios
      .get(`https://api.shopi.org/cities?order=name&where=country_id='mexico'`)
      .then((response) => {
        const cities = response.data;
        //console.log(cities, "<<< this is city response in city action");
        dispatch({
          type: FETCH_CITIES,
          payload: cities,
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};
