import { FETCH_CITIES } from "../actions/citiesActions.js";

const initState = {
  cities: []
};

const citiesReducer = (state = initState, action) => {
  if (action.type === FETCH_CITIES) {
    return {
      ...state,
      cities: action.payload
    };
  }

  return state;
};

export default citiesReducer;
